.section {
    padding: 32px 24px;
}

.title__section {
    font-size: 36px;
    font-weight: bold;
}
.img__container{
    padding-top: 20px;
    padding-bottom: 20px;
}

.ourservices__img{
    margin-left: auto;
    margin-right: auto;
}

.title__wrapper {
    margin: 0 auto 12px;
    text-align: center;
}

.desc__text {
    color:#5084AF;
    font-weight: 500;
}

.desc__list {
    height: 100%;
    top: 25%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.desc__item {
    padding: 0 4%;
    display: inline-block;
    text-align: center;
    min-height: 60px;
    margin-bottom: 24px;
}
@media screen and (min-width: 768px) {
    .title__section {
            font-size: 50px;
            line-height: 62px;
            font-weight: bold;
            padding-left: 15px;
        }
}


@media screen and (min-width: 1024px) {
    .ourservices__section {
            display: flex;
            justify-content: space-around;
        }
        
    .desc__list {
        display: flex;
        flex-direction: column;
    }


.desc__item {
    margin-bottom: 0px;
    display: flex;
    padding: 0 20px;
    text-align: center;
    min-height: 60px;
    margin-bottom: 0;
    align-items: center
}
.desc__text{
    padding-left: 20px;
    font-size: 18px;
}

.img__container {
    padding-top: 70px;
    padding-bottom: 0px;
}
}