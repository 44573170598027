.section{
    padding: 32px 24px;
}

.title__wrapper {
    margin: 0 auto 12px;
    text-align: center;
}


.desc__list{
    position: relative;
    height: 100%;
    top: 25%;
    padding: 20px 0;
}
.desc__item{
    padding: 0 4%;
    display: inline-block;
    text-align: center;
    min-height: 200px;
    margin-bottom: 24px;
}
.desc__item:not(:last-child) {
    margin-bottom: 20px;
}

.title {
    line-height: 32px;
    margin: 10px auto;
    font-size: 20px;
}
.text__wrapper{
        padding: 1% 4%;
}
.text{
    text-indent: 30px;
    font-size: 16px;
}
.text::first-letter {
    font-size: 24px;
    font-weight: 500;
}

@media screen and (min-width: 768px) {
.desc__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
    }
.desc__item {margin-bottom: 0px;}
                .title__section {
                    font-size: 50px;
                    line-height: 62px;
                    font-weight: bold;
                    padding-left: 15px;
                }

}