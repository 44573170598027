.section{
    box-sizing: border-box;
    padding: 32px 24px;
    margin-left: auto;
    margin-right: auto;
}

.title{
    margin: 0 auto 12px;
    text-align: center;
}
.title__section {
    font-size: 36px;
    font-weight: bold;
}

.img__container{
    position: relative;
}

.ourservices__img {
    margin-left: auto;
    margin-right: auto;
}

.wrapper {
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}
.choice__title{
    color: #1a405f;
    padding-top: 30px;
    padding-bottom: 5px;
}

.wrapper__choice{
    display: flex;
    width: 100%;
}
.choice__btn{
    width: 50%;
    text-align: center;
}
.text {
    flex-grow: 1;
}
.employee{
    font-size: 24px;
        font-weight: bold;
        color: #1a405f;
}

.checkbox {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.amount{
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    width: 400px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    color: #1a405f;
    text-shadow: 0px 0px hsla(0, 0%, 50%, .5),
        1px -1px hsla(0, 0%, 30%, .6);
}
@media screen and (min-width: 768px) {
    .title__section {
        font-size: 50px;
        line-height: 62px;
        font-weight: bold;
        padding-left: 15px;
    }
        .amount {
            font-size: 48px;
            font-weight: 700;
            width: 400px;
            bottom: 30px;
        }
}

@media screen and (min-width: 1024px) {
.wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 500px;
        padding: 8px 0;
    }
                .price__section {
                    display: flex;
                    justify-content: space-around;
        
                }
}
