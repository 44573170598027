.btn {
    display: inline-flex;
    justify-content: center;
    padding: 12px 12px;
    border-radius: 4px;
    width: 150px;
    border: none;
    font: inherit;
    cursor: pointer;
    background-color: #e2e5e8;
    color: #1a405f;
    font-weight: 400;
}

.btn.isSelected {
    background-color: #1a405f;
    color: #fff;
}

.btn:hover {
    background-color: #1a405f;
    color: #fff;
}

.btn:active {
    background-color: #1a405f;
    color: #fff;
}

@media screen and (min-width: 768px) {
    .btn {
        width: 210px;
        }
}