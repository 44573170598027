.header{
    position: relative;
    display: flex;
    justify-content: center
}

.header__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 15px;
}

.header__logo {
    font-size: 26px;
    line-height: 1.19em;
    letter-spacing: 0.03em;
    font-weight: 700;
    line-height: 1.36em;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 5px 5px 4px #212121;
}

.header__navigation {
    display: flex;
    margin-bottom: 10px;
   }

.header__contactItem{
    font-size: 16px;
    letter-spacing: 0.03em;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 5px 5px 4px #212121;
}

.header__contactList {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.14em;
    letter-spacing: 0.02em;

}

.header__contactItem:not(:last-child) {
    margin-right: 30px;
}

@media screen and (min-width: 768px) {
    .header__container {
        width: 700px;
    }
}

@media screen and (min-width: 1024px) {
.header__container {
        display: flex;
        flex-direction: row;
        padding: 24px 15px;
        width: 960px;
    }

.header__contactList {
        margin-left: auto;
    }
}

@media screen and (min-width: 1280px) {
    .header__container {
        width: 1200px;
    }
}
@media screen and (min-width: 1440px) {
    .header__container {
        width: 1400px;
    }
}